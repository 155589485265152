import React from "react";
import styled from "styled-components";

import CloseIcon from "@svgs/close.svg";

export const CookieConsent = styled.div`
  background-color: ${(props) => `${props.theme.colors.white}`};
  border-radius: 4px;
  border: ${(props) => `2px solid ${props.theme.colors.gunPowder}`};
  bottom: 4px;
  left: 4px;
  position: fixed;
  right: 4px;
  width: calc(100% - 8px);
  z-index: 100;

  @media (min-width: 992px) {
    left: auto;
    width: 450px;
  }

  .close-modal {
    border-radius: 50%;
  }

  h3 {
    margin-bottom: 0;
  }

  p,
  .approve {
    font-size: 0.9rem;
  }
`;

export const Close = styled((props) => <CloseIcon {...props} />)`
  height: 16px;
  width: 16px;
`;
