import React from "react";
import { ThemeProvider } from "styled-components";

// Color schema:
// https://coolors.co/f4f1de-d85531-3d405b-81b29a-f2cc8f

// Typo
// https://www.livechat.com/

// Free photos:
// https://www.pexels.com/
// https://unsplash.com/

// https://www.color-hex.com/color/df765a
// warn Warning: Event "xstate.after(200)#waitingMachine.aggregatingFileChanges" was sent to stopped service "waitingMachine". This service has already reached its final state, and will not transition.
// Event: {"type":"xstate.after(200)#waitingMachine.aggregatingFileChanges"}

const theme = {
  colors: {
    acapulco: "#81b29a",
    alabaster: "#fafafa",
    athensGray: "#ebebee",
    black: "#000",
    floralWhite: "#faf9f0",
    geyser: "#dee2e6",
    gin: "#e5efea",
    gunPowder: "#3d405b",
    linen: "#fbf1ee",
    manhattan: "#f2cc8f",
    merino: "#f4f1de",
    terracotta: "#D85531",
    white: "#fff",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
