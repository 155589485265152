import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/ubuntu/300.css";
import "@fontsource/ubuntu/300.css";
import "@fontsource/ubuntu/700.css";
import "@fontsource/ubuntu/500.css";
import "@fontsource/ubuntu/700.css";

import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  html * {
    font-family: "Ubuntu", sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
  }

  .main {
    padding-top: 5rem;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    letter-spacing: -0.2px;
  }

  h1 {
    font-size: 2rem;

    @media (min-width: 992px) { 
      font-size: 2.5rem;
    }

    @media (min-width: 1200px) { 
      font-size: 3rem;
    }
  }

  h2 {
    text-decoration: underline;
    text-decoration-color: ${(props) => `${props.theme.colors.terracotta}`};
    font-size: 1.875rem;

    @media (min-width: 992px) { 
      font-size: 2rem;
    }

    @media (min-width: 1200px) { 
      font-size: 2.125rem;
    }
  }

  h3 {
    font-size: 1.250rem;

    @media (min-width: 992px) { 
      font-size: 1.375rem;
    }

    @media (min-width: 1200px) { 
      font-size: 1.5rem;
    }
  }

  p {
    color: rgb(34, 34, 34);   
    font-size: 1.125rem;
  }

  .muted-link {
    color: transparent; 
    font-size: 0;
    line-height: 0; 
  }

  button {
    font-weight: 600;
  }

  a, button {
    svg {
      height: 24px;
      margin-right: 1.5rem;
      width: 24px;
      fill: ${(props) => `${props.theme.colors.black};`};

      &.svg-white {
        fill: ${(props) => `${props.theme.colors.white};`};
      }
    }

    &:last-of-type {
      svg {
        margin-right: 0;
      }
    }
  }

  iframe {
    &.white-border {
      border: ${(props) => ` 3px solid ${props.theme.colors.white}`};
    }
  }

  .p-small {
    font-size: 14px; 
  }

  .bg-merino {
    background: ${(props) => `${props.theme.colors.merino}`};
  }

  .bg-athensGray {
    background: ${(props) => `${props.theme.colors.athensGray}`};
  }

  .bg-gin {
    background: ${(props) => `${props.theme.colors.gin}`};
  }

  .bg-linen {
    background: ${(props) => `${props.theme.colors.linen}`};
  }

  ${"" /* Bootstrap overwrites */}
  
  .nav-link {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.6px;
  }

  ${"" /* Tour */}

  .view-container {
    .psv-autorotate-button,
    .psv-zoom-button {
      display: none;
    }
  }

  ${"" /* Menu */}

  .bm-menu-wrap {
    transition: all 0.125s ease 0s !important;

    &[aria-hidden="true"] {
      > div {
        display: none !important;
      }
    }

    .lang {
      color: ${(props) => `${props.theme.colors.white}`} !important;
      display: block;
      font-size: 18px !important;
      margin-bottom: 1rem !important;
    }

    hr {
      border-top: 1px solid rgba(255, 255, 255,.25) !important;
    }
  }

  .bm-menu-wrap,
  .bm-overlay {
    display: block !important;
    top: 0 !important;
    width: 100% !important;

    @media (min-width: 1200px) { 
      display: none !important;
    }
  }

  .bm-item {
    color: ${(props) => `${props.theme.colors.white}`};
    display: inline-block;
    margin-bottom: 0;
    text-decoration: none;
    transition: color 0.2s;
  }

  .bm-item:hover {
    color: white;
  }

  .bm-burger-button {
    display: block !important;
    height: 30px;
    position: fixed;
    right: 15px;
    top: 30px;
    width: 30px;

    @media (min-width: 1200px) { 
      display: none !important;
    }

    > button {
      font-size: 0 !important;
    }

    > span {
      right: 18px !important;
    }
  }

  .bm-burger-bars {
    border-radius: 2px;
    background: ${(props) => `${props.theme.colors.gunPowder}`};
    height: 4px !important;

    &:nth-child(2) {
      top: 34% !important;
    }

    &:nth-child(3) {
      top: 68% !important;
    }
  }

  .bm-cross-button {
    height: 36px !important;
    right: 15px !important;
    top: 27px !important;
    width: 36px !important;

    > button {
      color: transparent !important; 
      font-size: 0 !important;
      line-height: 0 !important; 
    }

    > span {
      height: 36px !important;
      right: 0 !important;
      top: 0 !important;
      width: 36px !important; 
    }
  }

  .bm-cross {
    background: ${(props) => `${props.theme.colors.white}`};
    height: 36px !important;
    right: 16px;
  }

  .bm-menu {
    background: ${(props) => `${props.theme.colors.gunPowder}`};
    font-size: 1.15em;
    padding: 2.5em 1.5em 0;
  }

  .bm-morph-shape {
    fill: ${(props) => `${props.theme.colors.gunPowder}`};
  }

  .bm-item-list {
    color: #b8b7ad;
  }

  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  ${"" /* Sweet alert 2 */}

  .swal2-title {
    text-decoration: none;
  }

  ${"" /* Gallery */}

  .border-athens-gray {
    .gallery {
      .gallery-item {
        img {
          border: ${(props) => `4px solid ${props.theme.colors.athensGray}`};
        }
      }
    }
  }

  .border-white {
    .gallery {
      .gallery-item {
        img {
          border: ${(props) => `4px solid ${props.theme.colors.white}`};
        }
      }
    }
  }

  /* Lightbox */

  .ReactModalPortal {
    .ril__closeButton {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=) no-repeat center;
    }

    .ril__zoomOutButton {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=) no-repeat center;
    }

    .ril__zoomInButton {
      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+) no-repeat center;
    }

    .ril__navButtonPrev {
      background: rgba(0, 0, 0, 0.2) url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==') no-repeat center
    }

    .ril__navButtonNext {
      background: rgba(0, 0, 0, 0.2) url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+) no-repeat center;
    }
  }
`;
