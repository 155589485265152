import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from "styled-components";

export const NavLink = styled((props) => <AnchorLink {...props} />)`
  background: transparent;
  color: ${(props) => `${props.theme.colors.white}`};
  display: block;
  font-size: 18px;
  font-weight: 500;
  margin-left: 1rem;
  padding: 0;
  transition: color 0.5s ease;
  width: 100%;

  &:hover {
    color: ${(props) => `${props.theme.colors.manhattan}`};
  }

  &:focus {
    outline: none;
  }

  &.bm-item {
    margin-left: 0;
  }
`;

export const Label = styled.p`
  color: ${(props) => `${props.theme.colors.terracotta}`};
  font-size: 18px;
  font-weight: 500;
`;
