import React from "react";
import { any } from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
// import { CookiesProvider } from "react-cookie";

import Theme from "@src/Theme";

import SEO from "@components/Seo";
import MenuHorizontal from "@components/MenuHorizontal";
import MenuVertical from "@components/MenuVertical";
import Footer from "@components/Footer";
import Cookie from "@components/Cookie";

import { withTrans } from "./../../i18e/withTrans";

import { GlobalStyle } from "./styles";

React.useLayoutEffect = React.useEffect;

const Layout = ({ children, t }) => {
  const data = useStaticQuery(graphql`
    query NonPageQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            anchor
            translate
          }
          phone
          phoneRef
        }
      }
    }
  `);

  const { menuLinks, phone, phoneRef } = data.site.siteMetadata;

  return (
    <>
      <SEO title={t(`seo.title`)} description={t(`seo.description`)} />

      <Theme>
        <div id="topPage"></div>
        <GlobalStyle />
        <MenuHorizontal menu={menuLinks} phone={phone} phoneRef={phoneRef} />
        <main className="main" id="main">
          <MenuVertical menu={menuLinks} />
          {children}
        </main>
        <Footer />
        {/* <Cookie /> */}
      </Theme>
    </>
  );
};

Layout.propTypes = {
  children: any,
};

Layout.defaultProps = {
  children: {},
};

export default withTrans(Layout);
