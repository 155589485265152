import styled from "styled-components";

export const Lang = styled.div`
  margin-right: 0;
`;

export const LinkTranslate = styled.a`
  color: ${(props) => `${props.theme.colors.black}`};
  cursor: pointer;
  display: inline-block;
  font-size: 0.95rem;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 28px;
  margin-right: 0.75rem;
  text-decoration: none;

  @media (min-width: 1200px) {
    margin-right: 1.25rem;
  }
`;
