import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useCookies } from "react-cookie";

import { withTrans } from "./../../i18e/withTrans";

import { CookieConsent, Close } from "./styles";

const Cookie = ({ t, i18n }) => {
  const [showModal, setShowModal] = useState(true);
  const [cookies, setCookie] = useCookies([process.env.GATSBY_WEBSITE_DOMAIN]);

  const HandleCookies = () => {
    if (
      !cookies[process.env.GATSBY_WEBSITE_DOMAIN] &&
      !cookies[process.env.GATSBY_WEBSITE_DOMAIN] === true
    ) {
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth();
      let day = today.getDate();
      let date = new Date(year + 1, month, day);

      setCookie(process.env.GATSBY_WEBSITE_DOMAIN, true, {
        path: "/",
        expires: date,
      });
      setShowModal(false);
    }
  };

  const HandleClose = () => {
    setShowModal(false);
  };

  return (
    cookies[process.env.GATSBY_WEBSITE_DOMAIN] !== undefined ||
    (showModal === true && (
      <CookieConsent>
        <Container>
          <Row>
            <Col>
              <div className="p-3 pb-4 w-100">
                <div className="d-flex align-items-center justify-content-between mb-2">
                  <h3>{t(`cookies.heading`)}</h3>
                  <Button
                    type="button"
                    variant="light"
                    onClick={() => HandleClose()}
                    className="close-modal"
                  >
                    <Close />
                  </Button>
                </div>
                <div>
                  <p>{t(`cookies.description`)}</p>
                  <Button
                    type="button"
                    variant="danger"
                    className="w-100 approve"
                    onClick={() => HandleCookies()}
                  >
                    {t(`cookies.button`)}
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </CookieConsent>
    ))
  );
};

export default withTrans(Cookie);
