import React, { useState } from "react";
import { arrayOf, object } from "prop-types";
import { slide as Menu } from "react-burger-menu";

import { withTrans } from "./../../i18e/withTrans";

import LanguageSelector from "@components/LanguageSelector";

import { NavLink, Label } from "./styles";

const MenuVertical = ({ t, i18n, menu }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const languagesList = {
    es: "Español",
    en: "English",
  };

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <Menu isOpen={menuOpen} onStateChange={(state) => handleStateChange(state)}>
      {menu.map((menuItem, menuIndex) => (
        <NavLink
          to={`/${menuItem.anchor}`}
          className="text-capitalize mb-3"
          stripHash
          onAnchorLinkClick={() => closeMenu()}
          key={`menu-vertical-nav-${menuIndex}`}
        >
          {t(menuItem.translate)}
        </NavLink>
      ))}
      <hr className="my-4" />
      <Label className="mb-3">{t(`languages`)}</Label>
      <LanguageSelector languagesList={languagesList} closeMenu={closeMenu} />
    </Menu>
  );
};

MenuVertical.propTypes = {
  menu: arrayOf(object).isRequired,
};

MenuVertical.defaultProps = {
  menu: {},
};

export default withTrans(MenuVertical);
