import React from "react";
import { string, arrayOf, object } from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LanguageSelector from "@components/LanguageSelector";

import { withTrans } from "./../../i18e/withTrans";

import Call from "@svgs/call.svg";

import {
  Brand,
  Nav,
  NavLink,
  CompanyName,
  InstagramIcon,
  FacebookIcon,
} from "./styles";

const NavMdUp = ({ t, i18n, menu, phone, phoneRef }) => {
  const languagesList = {
    es: "Esp",
    en: "Eng",
  };

  return (
    <Nav>
      <Container fluid>
        <Row className="align-items-center">
          <Col xs={12}>
            <div className="d-flex align-items-center justify-content-between nav-wrapper">
              <div className="d-flex align-items-center left-options">
                <Brand className="d-flex align-items-center" to={`/#topPage`}>
                  <img src="logo.png" alt="description here!!" width="120" height="120" />
                  <CompanyName></CompanyName>
                </Brand>
                <ul className="nav justify-content-center d-none d-xl-flex">
                  {menu.map((menuItem, menuIndex) => (
                    <li
                      className="nav-item"
                      key={`menu-horizontal-nav-${menuIndex}`}
                    >
                      <NavLink
                        to={`/${menuItem.anchor}`}
                        className="nav-link text-capitalize"
                        stripHash
                      >
                        {t(menuItem.translate)}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="d-flex justify-content-end align-items-center right-options">
                <a
                  href={`tel:${phoneRef}`}
                  className="phone muted-link d-none d-md-block"
                >
                  <Call />
                  <span>{phone}</span>
                </a>
                <span className="separator d-none d-md-block">|</span>
                <div className="d-none d-xl-inline">
                  <LanguageSelector languagesList={languagesList} />
                </div>
                <a href="https://www.instagram.com/villascabuya" className="muted-link social">
                  {t(`followInstagram`)}
                  <InstagramIcon />
                </a>
                <a href="https://www.facebook.com/villascabuya" className="muted-link social">
                  {t(`followFacebook`)}
                  <FacebookIcon />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Nav>
  );
};

NavMdUp.propTypes = {
  menu: arrayOf(object).isRequired,
  phone: string,
  phoneRef: string,
};

NavMdUp.defaultProps = {
  menu: {},
  phone: "",
  phoneRef: "",
};

export default withTrans(NavMdUp);
