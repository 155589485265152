import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled, { css } from "styled-components";

import Airbnb from "@svgs/airbnb.svg";
import Instagram from "@svgs/instagram.svg";
import Facebook from "@svgs/facebook.svg";

const SocialButton = css`
  height: 32px;
  width: 32px;

  &:hover {
    fill: ${(props) => `${props.theme.colors.terracotta}`};
  }
`;

export const Brand = styled((props) => <AnchorLink {...props} />)`
  height: 82px;
  text-decoration: none;
`;

export const Nav = styled.div`
  background: ${(props) => `${props.theme.colors.white}`};
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 12px;
  position: fixed;
  width: 100%;
  z-index: 200;

  .nav-wrapper {
    margin: 0 auto;
    max-width: 2200px;

    .left-options {
      ul {
        li {
          &:last-of-type {
            a {
              padding-right: 0;
            }
          }
        }
      }
    }

    .right-options {
      flex-grow: 1;

      .phone {
        color: rgb(34, 34, 34);
        display: block;
        font-size: 0.95rem;
        margin-top: 4px;
        line-height: 28px;

        svg {
          height: 20px;
          margin-right: 0.75rem;
          position: relative;
          width: 20px;
        }
      }

      .separator {
        padding: 0 0.75rem;

        @media (min-width: 1200px) {
          padding: 1.25rem;
        }
      }

      .muted-text {
        height: 32px;
        width: 32px;
      }

      .social {
        svg {
          margin-right: 0.75rem;

          @media (min-width: 1200px) {
            margin-right: 1.25rem;
          }
        }

        &:last-of-type {
          svg {
            margin-right: calc(30px + 0.75rem);

            @media (min-width: 1200px) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
`;

export const NavLink = styled((props) => <AnchorLink {...props} />)`
  background: transparent;
  color: ${(props) => `${props.theme.colors.black}`};
  display: inline-block;
  font-size: 0.95rem;
  margin: 0;
  padding: 0 1.25rem 0 0;
  position: relative;
  z-index: 1;
`;

export const Text = styled.div``;

export const CompanyName = styled.span`
  color: ${(props) => `${props.theme.colors.black}`};
  font-size: 1.375rem;
  font-weight: 700;
  margin-left: 0.375rem;
  margin-right: 1.25rem;
`;

export const InstagramIcon = styled((props) => <Instagram {...props} />)`
  ${SocialButton}
`;

export const FacebookIcon = styled((props) => <Facebook {...props} />)`
  ${SocialButton}
`;

export const AirbnbIcon = styled((props) => <Airbnb {...props} />)`
  fill: ${(props) => `${props.theme.colors.white}`};
  height: 24px;
  width: 24px;
`;
