import React, { useState } from "react";
import { object, func } from "prop-types";
import { useTranslation } from "react-i18next";

import { Lang, LinkTranslate } from "./styles";

const LanguageSelector = ({ languagesList, closeMenu }) => {
  const { t, i18n } = useTranslation();

  const [values, setValues] = useState({
    language: "es",
  });

  const changeLocale = (key) => {
    i18n.changeLanguage(key);
    setValues((oldValues) => ({
      ...oldValues,
      language: key,
    }));
  };

  return (
    <Lang className="languages">
      {Object.keys(languagesList).map((key) => {
        return (
          <LinkTranslate
            key={key}
            onClick={() => {
              changeLocale(key);
              closeMenu();
            }}
            className="lang"
            value={key}
          >
            {languagesList[key]}
          </LinkTranslate>
        );
      })}
    </Lang>
  );
};

LanguageSelector.propTypes = {
  languagesList: object.isRequired,
  closeMenu: func,
};

LanguageSelector.defaultProps = {
  languagesList: {},
  closeMenu: () => {},
};

export default LanguageSelector;
